import { t } from 'i18next'

import { isPriceCategoryIdDto, type SingleTicketDto } from '@hcr/api/consumer'

import { LocaleNamespace } from '../../models'

type CreateSingleTicketNameStringOptions = Pick<SingleTicketDto, 'category_id' | 'timeslot'>

export const createSingleTicketNameString = ({
  category_id,
  timeslot,
}: CreateSingleTicketNameStringOptions): string => {
  const categoryName = isPriceCategoryIdDto(category_id)
    ? t(category_id, { ns: LocaleNamespace.PriceCategoryId })
    : category_id

  if (!timeslot) {
    return categoryName
  }

  return `${categoryName} ${timeslot}`
}
