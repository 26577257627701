import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Card, Stack, styled, Typography } from '@mui/material'
import DOMPurify from 'dompurify'
import type { FC } from 'react'

import { isNotNull, isNull } from '@hcr/utils'

import { COLORS, ICONS } from '../../../../constants'
import type { Link } from '../../../../models'
import { optimizelyCss } from '../../../../styles'
import { createLinkExternalProps, unit } from '../../../../utils'
import { LinkUnstyled } from '../../../navigation'
import type { CardVariant } from '../types'
import { CardEmbedment } from '../types'

import {
  cardEmbedmentCss,
  cardVariantCss,
  CONTENT_GAP,
  CONTENT_PADDING_X,
  CONTENT_PADDING_Y,
  overflowGradientVariantCss,
  typographyVariantCss,
} from './styles'

export interface CardPromotionProps {
  description: string
  embedment: CardEmbedment
  link: Link | null
  title: string
  variant: CardVariant
}

export const CardPromotion: FC<CardPromotionProps> = ({ description, embedment, link, title, variant }) => {
  const content = (
    <Card css={[cardEmbedmentCss[embedment], cardVariantCss[variant]]}>
      <Stack
        gap={unit(CONTENT_GAP)}
        paddingY={unit(CONTENT_PADDING_Y)}
        paddingX={unit(CONTENT_PADDING_X)}
        height='100%'
      >
        <Typography
          variant={embedment === CardEmbedment.Standalone ? 'headlineL' : 'displayM'}
          color={COLORS.white}
          css={typographyVariantCss[variant]}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title) }}
        />
        <Box position='relative' overflow='hidden' flexGrow={1}>
          <Typography
            variant='bodyM'
            color={COLORS.white}
            css={optimizelyCss}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
          />
          {embedment === CardEmbedment.Carousel && (
            <Box position='absolute' top={0} right={0} bottom={0} left={0} css={overflowGradientVariantCss[variant]} />
          )}
        </Box>
        {isNotNull(link) && (
          <CtaButton endIcon={<FontAwesomeIcon icon={ICONS.farArrowRight} />} variant='text' color='white'>
            {link.label}
          </CtaButton>
        )}
      </Stack>
    </Card>
  )

  if (isNull(link)) {
    return content
  }

  return (
    <LinkUnstyled to={link.to} sx={{ cursor: 'pointer' }} {...createLinkExternalProps(link.isExternal)}>
      {content}
    </LinkUnstyled>
  )
}

const CtaButton = styled(Button)`
  align-self: flex-end;
  text-align: right;
  text-transform: uppercase;
  width: fit-content;
`
