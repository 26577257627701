import { Button } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { LinkUnstyled } from '@hcr/ui'
import { isNotUndefined } from '@hcr/utils'

import { useHoliday } from '../../../../contexts'
import { useRoomDetailsQuery } from '../../../../hooks'
import { Path } from '../../../../models'
import { to } from '../../../../utils'
import { BookingDates, LayoutBookingOverview, LayoutBookingOverviewContent } from '../common'

import { BookingOverviewCompactLoading } from './BookingOverviewCompactLoading'

export const BookingOverviewCompact: FC = () => {
  const { t } = useTranslation()
  const {
    data: { accommodation, destination },
    ...holiday
  } = useHoliday()

  const roomDetails = useRoomDetailsQuery(
    { destinationId: String(destination?.destinationId), roomId: String(accommodation?.room_type_id) },
    {
      enabled:
        isNotUndefined(destination) && isNotUndefined(accommodation) && isNotUndefined(accommodation.room_type_id),
    }
  )

  if (holiday.isSuccess && isNotUndefined(accommodation)) {
    return (
      <LayoutBookingOverview>
        <LayoutBookingOverviewContent>
          <BookingDates
            checkInDate={accommodation.start_date}
            checkInHour={roomDetails.data?.checkInHour}
            checkOutDate={accommodation.end_date}
            checkOutHour={roomDetails.data?.checkOutHour}
          />
          <Button component={LinkUnstyled} to={to(Path.Booking)} variant='contained' color='primary'>
            {t('booking-overview.booking-and-program')}
          </Button>
        </LayoutBookingOverviewContent>
      </LayoutBookingOverview>
    )
  }

  return <BookingOverviewCompactLoading />
}
