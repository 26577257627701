import type { AutocompleteProps as MuiAutoCompleteProps } from '@mui/material'
import { Autocomplete as MuiAutocomplete, TextField } from '@mui/material'

import { isNotUndefined } from '@hcr/utils'

interface AutocompleteProps<T>
  extends Pick<
    MuiAutoCompleteProps<T, false, true, false>,
    'className' | 'getOptionLabel' | 'onChange' | 'options' | 'value'
  > {
  errorText?: string
  isRequired: boolean
  label?: string
}

export const Autocomplete = <T,>({
  className,
  errorText,
  getOptionLabel,
  isRequired,
  label,
  onChange,
  options,
  value,
}: AutocompleteProps<T>) => (
  <MuiAutocomplete
    disableClearable
    disablePortal
    value={value}
    onChange={onChange}
    renderInput={params => (
      <TextField
        {...params}
        className={className}
        label={label}
        error={isNotUndefined(errorText)}
        helperText={errorText}
        required={isRequired}
      />
    )}
    getOptionLabel={getOptionLabel}
    options={options}
  />
)
