import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import type { Dispatch, FC, MouseEvent, SetStateAction } from 'react'
import { useState } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { Controller, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { z } from 'zod'

import type { CountryDto } from '@hcr/api/consumer'
import { Alert, Autocomplete, ButtonLoading, Checkbox, COLORS, ICONS, LinkTrans, LoadingState, unit } from '@hcr/ui'
import { isNotEmpty, isNotUndefined, logger } from '@hcr/utils'

import { LINK_CONFIG, SENTRY_CONFIG } from '../../../../../configs'
import { useCountriesQuery, useUserOnboardingCompletionMutation } from '../../../../../hooks'
import { createCountryCodes, selectCountriesSortedByTranslationAsc } from '../../../../../utils'

interface OnboardingCompletionFormProps {
  email: string
  firstName: string
  lastName: string
  nonce: string
  setIsFormSubmitted: Dispatch<SetStateAction<boolean>>
}

const FINLAND_COUNTRY_CODE = '+358'
const FINLAND_COUNTRY_NAME: CountryDto = 'Finland'

export const OnboardingCompletionForm: FC<OnboardingCompletionFormProps> = ({
  email,
  firstName,
  lastName,
  nonce,
  setIsFormSubmitted,
}) => {
  const { t } = useTranslation()
  const [showPassword, setShowPassword] = useState(false)

  const countries = useCountriesQuery({ select: selectCountriesSortedByTranslationAsc })

  const userOnboardingCompletion = useUserOnboardingCompletionMutation()

  const handleClickShowPassword = () => setShowPassword(showPassword => !showPassword)

  const handleMouseEvents = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const schema = z
    .strictObject({
      street_address: z
        .string()
        .trim()
        .min(1, { message: t('validation.required') })
        .max(100, { message: t('validation.characters-count-not-exceed', { count: 100 }) }),
      postal_code: z
        .string()
        .trim()
        .min(1, { message: t('validation.required') })
        .max(11, { message: t('validation.characters-count-not-exceed', { count: 11 }) }),
      city: z
        .string()
        .trim()
        .min(1, { message: t('validation.required') })
        .max(40, { message: t('validation.characters-count-not-exceed', { count: 40 }) }),
      country: z
        .string({ invalid_type_error: t('validation.required'), required_error: t('validation.required') })
        .trim()
        .min(1, { message: t('validation.required') }),
      password: z
        .string()
        .regex(/[A-Z]+/, { message: t('validation.type-password') })
        .regex(/[a-z]+/, { message: t('validation.type-password') })
        .regex(/[0-9]+/, { message: t('validation.type-password') })
        .regex(/[@#$%^&*\-_!+=[\]{}|\\:',.?/`~"();<>]+/, { message: t('validation.type-password') })
        .min(12, { message: t('validation.type-password') })
        .max(40, { message: t('validation.type-password') }),
      country_code: z
        .string()
        .trim()
        .regex(/^\+[0-9]{1,3}$/, { message: t('validation.type-country-code') }),
      phone: z
        .string()
        .trim()
        .min(6, { message: t('validation.digits-count-at-least', { count: 6 }) })
        .max(15, { message: t('validation.digits-count-not-exceed', { count: 15 }) })
        .regex(/^[0-9]{1,}$/, { message: t('validation.type-number') }),
      terms_of_use_consent: z.boolean().refine(value => value, { message: t('validation.required') }),
      email_marketing_consent: z.boolean(),
      nonce: z.string(),
    })
    .refine(
      data => {
        if (data.country === 'Finland') {
          return /^[0-9]{5}$/.test(data.postal_code)
        }
        return true
      },
      {
        message: t('validation.digits-count-exact', { count: 5 }),
        path: ['postal_code'],
      }
    )

  type Schema = z.infer<typeof schema>

  const form = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      // Note: HCR has decided that the form will not be filled in automatically, even if the data is returned by the Consumer API
      street_address: '',
      postal_code: '',
      city: '',
      country: FINLAND_COUNTRY_NAME,
      password: '',
      country_code: FINLAND_COUNTRY_CODE,
      phone: '',
      terms_of_use_consent: false,
      email_marketing_consent: false,
      nonce,
    },
  })

  const onSubmit: SubmitHandler<Schema> = async data => {
    try {
      const { terms_of_use_consent, ...payload } = data
      await userOnboardingCompletion.mutateAsync({
        ...payload,
        first_name: firstName,
        last_name: lastName,
        email_address: email,
      })
      setIsFormSubmitted(true)
    } catch {
      logger.error('Failed to complete user onboarding:', data)
    }
  }

  if (countries.isError) {
    return <Alert severity='error' title={t('error.error')} description={t('error.generic')} />
  }

  if (countries.isSuccess) {
    return (
      <Box>
        <Form onSubmit={form.handleSubmit(onSubmit)} noValidate>
          <TextField
            className={SENTRY_CONFIG.privacy.className}
            value={firstName}
            label={t('onboarding.first-name')}
            disabled
          />
          <TextField
            className={SENTRY_CONFIG.privacy.className}
            value={lastName}
            label={t('onboarding.last-name')}
            disabled
          />
          <TextField
            className={SENTRY_CONFIG.privacy.className}
            value={email}
            label={t('onboarding.email-address')}
            disabled
          />
          <Controller
            control={form.control}
            name='street_address'
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                className={SENTRY_CONFIG.privacy.className}
                value={value}
                onChange={onChange}
                required
                label={t('onboarding.address')}
                error={isNotUndefined(error)}
                helperText={error?.message}
              />
            )}
          />
          <Controller
            control={form.control}
            name='postal_code'
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                className={SENTRY_CONFIG.privacy.className}
                inputMode='numeric'
                value={value}
                onChange={onChange}
                required
                label={t('onboarding.zip-code')}
                error={isNotUndefined(error)}
                helperText={error?.message}
                placeholder='00000'
              />
            )}
          />
          <Controller
            control={form.control}
            name='city'
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                className={SENTRY_CONFIG.privacy.className}
                value={value}
                onChange={onChange}
                required
                label={t('onboarding.city')}
                error={isNotUndefined(error)}
                helperText={error?.message}
              />
            )}
          />
          <Controller
            control={form.control}
            name='country'
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Autocomplete
                className={SENTRY_CONFIG.privacy.className}
                errorText={error?.message}
                label={t('onboarding.country')}
                getOptionLabel={option => countries.data[option as CountryDto]}
                options={Object.keys(countries.data)}
                isRequired
                onChange={(_, value) => onChange(value)}
                value={value}
              />
            )}
          />

          <FormField>
            <InputLabel>
              <Typography variant='labelM'>{t('onboarding.phone-number')}*</Typography>
            </InputLabel>
            <FormFieldPhone>
              <Controller
                control={form.control}
                name='country_code'
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <Autocomplete
                    className={SENTRY_CONFIG.privacy.className}
                    errorText={error?.message}
                    options={createCountryCodes()}
                    isRequired
                    onChange={(_, value) => onChange(value)}
                    value={value}
                  />
                )}
              />

              <Controller
                control={form.control}
                name='phone'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    className={SENTRY_CONFIG.privacy.className}
                    inputMode='tel'
                    value={value}
                    onChange={onChange}
                    required
                    error={isNotUndefined(error)}
                    helperText={error?.message}
                    placeholder={t('onboarding.phone-number')}
                  />
                )}
              />
            </FormFieldPhone>
          </FormField>
          <FormField>
            <InputLabel>
              <Typography variant='labelM'>{t('onboarding.password')}*</Typography>
            </InputLabel>
            <Controller
              control={form.control}
              name='password'
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  className={SENTRY_CONFIG.privacy.className}
                  value={value}
                  type={showPassword ? 'text' : 'password'}
                  onChange={onChange}
                  required
                  error={isNotUndefined(error)}
                  helperText={error?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label={t('onboarding.toggle-password-visibility')}
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseEvents}
                          onMouseUp={handleMouseEvents}
                          edge='end'
                        >
                          <FontAwesomeIcon
                            icon={ICONS.farEyeSlash}
                            color={showPassword ? COLORS.semantic.disabled : COLORS.black}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </FormField>
          <Typography variant='meta' paddingLeft={unit(3)}>
            {t('onboarding.required-information')}
          </Typography>
          <Controller
            control={form.control}
            name='terms_of_use_consent'
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormControl error={isNotUndefined(error)} component='fieldset'>
                <FormControlLabel
                  label={
                    <Typography variant='bodyM'>
                      <Trans i18nKey='onboarding.consent-terms-of-use'>
                        I agree to Holiday Club's
                        <LinkTrans to={LINK_CONFIG.termsOfDigitalServices} target='_blank' rel='external'>
                          terms of use
                        </LinkTrans>
                      </Trans>
                    </Typography>
                  }
                  control={<Checkbox checked={value} onChange={onChange} required />}
                />
                {isNotUndefined(error) && isNotUndefined(error.message) && (
                  <FormHelperText>{error.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />
          <Controller
            control={form.control}
            name='email_marketing_consent'
            render={({ field: { value, onChange } }) => (
              <FormControlLabel
                label={<Typography variant='bodyM'>{t('onboarding.consent-email-marketing')}</Typography>}
                control={<Checkbox checked={value} onChange={onChange} />}
              />
            )}
          />
          {userOnboardingCompletion.isError && (
            <Alert severity='error' title={t('error.error')} description={t('error.generic')} />
          )}
          <ButtonLoading
            disabled={isNotEmpty(Object.keys(form.formState.errors))}
            isLoading={form.formState.isSubmitting}
            type='submit'
            variant='contained'
            color='primary'
          >
            {t('onboarding.finalize-account')}
          </ButtonLoading>
        </Form>
      </Box>
    )
  }

  return <LoadingState />
}

const Form = styled('form')`
  display: flex;
  flex-direction: column;
  gap: ${unit(5)};
`

const FormField = styled(Stack)`
  gap: ${unit(1.5)};
`

const FormFieldPhone = styled(Box)`
  display: grid;

  ${props => props.theme.breakpoints.down('md')} {
    grid-template-columns: 35% 65%;
  }

  ${props => props.theme.breakpoints.up('md')} {
    grid-template-columns: 25% 75%;
  }
`
