import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { find, flow, getPropertyValue, hasPropertyValue, isNotNull, isNotUndefined, isUndefined } from '@hcr/utils'

import { useDestinationDetailsQuery, useDestinationsQuery, useIdToken, useSingleTicketsQuery } from '../../../../hooks'
import { DateFormat, DateTimeFormat, LocaleNamespace, Path } from '../../../../models'
import { createSingleTicketNameString, currencyFormatter, dateFormatter, isDestinationOf, to } from '../../../../utils'
import { ButtonBuyTicket, LayoutNavigationBackError500, LayoutNavigationBackLoading } from '../../../common'
import { LayoutTicketDetailsActive, TicketValidityActive } from '../common'

interface TicketDetailsSingleActiveOneProps {
  resortsIds: number[]
  ticketId: number
}

const BACK_NAVIGATION_PATH = to(Path.Booking)

export const TicketDetailsSingleActiveOne: FC<TicketDetailsSingleActiveOneProps> = ({ resortsIds, ticketId }) => {
  const { t } = useTranslation()
  const idToken = useIdToken()

  const singleTicket = useSingleTicketsQuery(
    { idToken: String(idToken), resortsIds },
    {
      select: find(hasPropertyValue('ticket_id', ticketId)),
      enabled: isNotNull(idToken),
    }
  )

  const destination = useDestinationsQuery({
    select: flow(getPropertyValue('destinations'), find(isDestinationOf(singleTicket.data))),
    enabled: isNotUndefined(singleTicket.data),
  })

  const destinationDetails = useDestinationDetailsQuery(
    { destinationId: String(destination.data?.destinationId) },
    { enabled: isNotUndefined(destination.data?.destinationId) }
  )

  if (
    singleTicket.isError ||
    (singleTicket.isSuccess && isUndefined(singleTicket.data)) ||
    destination.isError ||
    (destination.isSuccess && isUndefined(destination.data)) ||
    destinationDetails.isError
  ) {
    return <LayoutNavigationBackError500 to={BACK_NAVIGATION_PATH} />
  }

  if (singleTicket.isSuccess && destination.isSuccess && destinationDetails.isSuccess) {
    if (
      isNotUndefined(singleTicket.data) &&
      isNotNull(singleTicket.data.activation_date) &&
      isNotNull(singleTicket.data.activation_expiration_date)
    ) {
      return (
        <LayoutTicketDetailsActive
          additionalInfo={[
            { label: t('tickets.resort'), value: destinationDetails.data.name },
            { label: t('tickets.price'), value: currencyFormatter.format(singleTicket.data.price) },
            {
              label: t('tickets.expiration-date'),
              value: dateFormatter.format(DateTimeFormat.Standard, singleTicket.data.activation_expiration_date),
            },
            {
              label: t('tickets.booking-date'),
              value: dateFormatter.format(DateFormat.Standard, singleTicket.data.activation_possible_from_date),
            },
            { label: t('tickets.booking-number'), value: ticketId },
          ]}
          backNavigationPath={BACK_NAVIGATION_PATH}
          cta={<ButtonBuyTicket resortId={singleTicket.data.resort_id} variant='outlined' color='black' />}
          destinationLogoUrl={destinationDetails.data.logoUrl}
          destinationName={destinationDetails.data.name}
          guests={t(singleTicket.data.ticket_group, { ns: LocaleNamespace.TicketGroup })}
          name={createSingleTicketNameString(singleTicket.data)}
          validity={t('tickets.enjoy-your-visit')}
        >
          <TicketValidityActive>
            {dateFormatter.format(DateTimeFormat.Standard, singleTicket.data.activation_date)}
          </TicketValidityActive>
        </LayoutTicketDetailsActive>
      )
    }

    return <LayoutNavigationBackError500 to={BACK_NAVIGATION_PATH} />
  }

  return <LayoutNavigationBackLoading to={BACK_NAVIGATION_PATH} />
}
