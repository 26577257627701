import { Box, Button, Stack, Typography } from '@mui/material'
import DOMPurify from 'dompurify'
import type { FC } from 'react'
import { Navigate } from 'react-router-dom'

import { COLORS, LinkUnstyled, optimizelyCss, unit } from '@hcr/ui'
import { isNotNull, isNotUndefined } from '@hcr/utils'

import { useHoliday } from '../../../contexts'
import { useTimeshareIntroductionInfoQuery, useIdToken } from '../../../hooks'
import { Path } from '../../../models'
import { isFlyAccommodation, to } from '../../../utils'
import { LayoutNavigationBack, LayoutNavigationBackLoading, WarningMissingDataFloating } from '../../common'

const BACK_NAVIGATION_PATH = to(Path.Booking)

export const BookingTimeshareIntroductionFallback: FC = () => {
  const idToken = useIdToken()
  const {
    data: { accommodation },
    ...holiday
  } = useHoliday()

  const hasTimeshareIntroductionInfo = isNotUndefined(accommodation) && isFlyAccommodation(accommodation)

  const timeshareIntroductionInfo = useTimeshareIntroductionInfoQuery({
    enabled: isNotNull(idToken) && hasTimeshareIntroductionInfo,
  })

  if (!hasTimeshareIntroductionInfo) {
    return <Navigate to={Path.Booking} replace />
  }

  if (holiday.isSuccess && timeshareIntroductionInfo.isSuccess) {
    return (
      <LayoutNavigationBack to={BACK_NAVIGATION_PATH} arrowVariant='text'>
        <WarningMissingDataFloating />
        <Box bgcolor={COLORS.white} minHeight='100vh'>
          <Stack gap={unit(4)} paddingTop={unit(24)} paddingX={unit(7)} paddingBottom={unit(4.5)}>
            <Typography variant='headlineL'>{timeshareIntroductionInfo.data.title}</Typography>
            <Typography variant='bodyM'>{timeshareIntroductionInfo.data.ingress}</Typography>

            <Typography paddingY={unit(2)} variant='titleS'>
              {timeshareIntroductionInfo.data.subHeading}
            </Typography>

            <Typography
              variant='bodyM'
              css={[optimizelyCss]}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(timeshareIntroductionInfo.data.content) }}
            />

            {timeshareIntroductionInfo.data.formLink && (
              <Button
                component={LinkUnstyled}
                to={timeshareIntroductionInfo.data.formLink.url}
                sx={{ marginTop: unit(2) }}
                target='_blank'
                rel='external'
                variant='contained'
                color='primary'
              >
                {timeshareIntroductionInfo.data.formLink.label}
              </Button>
            )}
          </Stack>
        </Box>
      </LayoutNavigationBack>
    )
  }

  return <LayoutNavigationBackLoading to={BACK_NAVIGATION_PATH} />
}
