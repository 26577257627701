import type { FC } from 'react'
import { useMemo } from 'react'

import {
  filter,
  flow,
  getPropertyValue,
  isNotEmpty,
  isNotNull,
  isNotUndefined,
  isSame,
  isUndefined,
  map,
  unique,
} from '@hcr/utils'

import { useHoliday } from '../../../contexts'
import {
  useAccommodationDetailsQuery,
  useActivitiesQuery,
  useDestinationDetailsQuery,
  useIdToken,
  useSingleTicketsQuery,
} from '../../../hooks'
import { WarningMissingData } from '../../common'

import { createTicketPreview, LayoutTickets } from './common'
import { TicketsEmpty } from './TicketsEmpty'
import { TicketsLoading } from './TicketsLoading'
import { prepareTicketPreviewsData } from './utils'

const ticketPreview = createTicketPreview()

export const Tickets: FC = () => {
  const idToken = useIdToken()
  const {
    data: { accommodation, destination },
    ...holiday
  } = useHoliday()

  const packageTickets = useAccommodationDetailsQuery(
    { idToken: String(idToken), accommodationId: Number(accommodation ? accommodation.accommodation_id : null) },
    {
      select: getPropertyValue('package_tickets'),
      enabled: isNotNull(idToken) && isNotUndefined(accommodation),
    }
  )

  // Note: The Consumer API resort_id is represented by hotelId and mokkiId in the Optimizely API. Depending on the type of resort, these values may be the same, different or null.
  const resortsIds = useDestinationDetailsQuery(
    { destinationId: String(destination?.destinationId) },
    {
      select: flow(
        destinationDetails => [destinationDetails.hotelId, destinationDetails.mokkiId],
        filter(isNotNull),
        unique(isSame),
        map(Number)
      ),
      enabled: isNotUndefined(destination),
    }
  )

  const activities = useActivitiesQuery(
    { idToken: String(idToken), resortsIds: resortsIds.data ?? [] },
    { enabled: isNotNull(idToken) && isNotUndefined(resortsIds.data) && isNotEmpty(resortsIds.data) }
  )

  const singleTickets = useSingleTicketsQuery(
    { idToken: String(idToken), resortsIds: resortsIds.data ?? [] },
    { enabled: isNotNull(idToken) && isNotUndefined(resortsIds.data) && isNotEmpty(resortsIds.data) }
  )

  const ticketPreviewsData = useMemo(
    () => prepareTicketPreviewsData([packageTickets.data, activities.data, singleTickets.data].filter(isNotUndefined)),
    [packageTickets.data, activities.data, singleTickets.data]
  )

  if (holiday.isSuccess && isUndefined(accommodation) && isUndefined(destination)) {
    return <TicketsEmpty />
  }

  if (packageTickets.isSuccess || activities.isSuccess || singleTickets.isSuccess) {
    if (isNotEmpty(ticketPreviewsData)) {
      return (
        <LayoutTickets>
          {(packageTickets.isError || resortsIds.isError || activities.isError || singleTickets.isError) && (
            <WarningMissingData />
          )}
          {ticketPreviewsData.map(ticketPreview)}
        </LayoutTickets>
      )
    }

    return <TicketsEmpty />
  }

  return <TicketsLoading />
}
