import { Box, Stack, Typography } from '@mui/material'
import DOMPurify from 'dompurify'
import type { FC, PropsWithChildren, ReactNode } from 'react'
import { type To } from 'react-router-dom'

import { Chip, optimizelyCss, unit } from '@hcr/ui'

import { createCouponStatus } from '../../../../../utils'
import { LayoutBackNavigateCoupon } from '../layout-back-navigate-coupon'

interface LayoutCouponDetailsInactiveProps extends PropsWithChildren {
  backNavigationPath: To
  caption: string
  couponIconUrl: string
  couponImageUrl: string
  description: string
  heading: string
  isUsed: boolean
  toBeActivated: boolean
  validity: ReactNode
}

export const LayoutCouponDetailsInactive: FC<LayoutCouponDetailsInactiveProps> = ({
  backNavigationPath,
  caption,
  couponIconUrl,
  couponImageUrl,
  children,
  description,
  heading,
  isUsed,
  toBeActivated,
  validity,
}) => {
  const couponStatus = createCouponStatus({ toBeActivated, is_used: isUsed })

  return (
    <LayoutBackNavigateCoupon to={backNavigationPath} headerImageUrl={couponImageUrl}>
      <Stack sx={{ gap: unit(3), alignItems: 'center', paddingTop: unit(7) }}>
        <Box component='img' src={couponIconUrl} width={unit(8)} height={unit(8)} />
        <Typography variant='uppercaseExtraSmall'>{caption}</Typography>
        <Chip component='coupon' text={couponStatus.label} variant={couponStatus.value} />
      </Stack>

      <Box sx={{ padding: unit(4), textAlign: 'center' }}>
        <Typography variant='headlineS'>{heading}</Typography>
      </Box>

      <Stack sx={{ gap: unit(3), paddingTop: 0, padding: unit(4), alignItems: 'center', textAlign: 'center' }}>
        <Typography
          variant='bodyM'
          css={[optimizelyCss]}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
        />
      </Stack>

      <Stack
        sx={{ gap: unit(1), justifyContent: 'center', alignItems: 'center', paddingTop: unit(7), padding: unit(4) }}
      >
        <Typography variant='bodyM'>{validity}</Typography>
      </Stack>

      {children}
    </LayoutBackNavigateCoupon>
  )
}
