import { z } from 'zod'

export const CountryDtoSchema = z.union([
  z.literal('Afghanistan'),
  z.literal('Aland Islands'),
  z.literal('Albania'),
  z.literal('Algeria'),
  z.literal('Andorra'),
  z.literal('Angola'),
  z.literal('Anguilla'),
  z.literal('Antarctica'),
  z.literal('Antigua and Barbuda'),
  z.literal('Argentina'),
  z.literal('Armenia'),
  z.literal('Aruba'),
  z.literal('Australia'),
  z.literal('Austria'),
  z.literal('Azerbaijan'),
  z.literal('Bahamas'),
  z.literal('Bahrain'),
  z.literal('Bangladesh'),
  z.literal('Barbados'),
  z.literal('Belarus'),
  z.literal('Belgium'),
  z.literal('Belize'),
  z.literal('Benin'),
  z.literal('Bermuda'),
  z.literal('Bhutan'),
  z.literal('Bolivia, Plurinational State of'),
  z.literal('Bonaire, Sint Eustatius and Saba'),
  z.literal('Bosnia and Herzegovina'),
  z.literal('Botswana'),
  z.literal('Bouvet Island'),
  z.literal('Brazil'),
  z.literal('British Indian Ocean Territory'),
  z.literal('Brunei Darussalam'),
  z.literal('Bulgaria'),
  z.literal('Burkina Faso'),
  z.literal('Burundi'),
  z.literal('Cambodia'),
  z.literal('Cameroon'),
  z.literal('Canada'),
  z.literal('Cape Verde'),
  z.literal('Cayman Islands'),
  z.literal('Central African Republic'),
  z.literal('Chad'),
  z.literal('Chile'),
  z.literal('China'),
  z.literal('Chinese Taipei'),
  z.literal('Christmas Island'),
  z.literal('Cocos (Keeling) Islands'),
  z.literal('Colombia'),
  z.literal('Comoros'),
  z.literal('Congo'),
  z.literal('Congo, the Democratic Republic of the'),
  z.literal('Cook Islands'),
  z.literal('Costa Rica'),
  z.literal('Cote d&#39;Ivoire'),
  z.literal('Croatia'),
  z.literal('Cuba'),
  z.literal('Curaçao'),
  z.literal('Cyprus'),
  z.literal('Czech Republic'),
  z.literal('Denmark'),
  z.literal('Djibouti'),
  z.literal('Dominica'),
  z.literal('Dominican Republic'),
  z.literal('Ecuador'),
  z.literal('Egypt'),
  z.literal('El Salvador'),
  z.literal('Equatorial Guinea'),
  z.literal('Eritrea'),
  z.literal('Estonia'),
  z.literal('Ethiopia'),
  z.literal('Falkland Islands (Malvinas)'),
  z.literal('Faroe Islands'),
  z.literal('Fiji'),
  z.literal('Finland'),
  z.literal('France'),
  z.literal('French Guiana'),
  z.literal('French Polynesia'),
  z.literal('French Southern Territories'),
  z.literal('Gabon'),
  z.literal('Gambia'),
  z.literal('Georgia'),
  z.literal('Germany'),
  z.literal('Ghana'),
  z.literal('Gibraltar'),
  z.literal('Greece'),
  z.literal('Greenland'),
  z.literal('Grenada'),
  z.literal('Guadeloupe'),
  z.literal('Guatemala'),
  z.literal('Guernsey'),
  z.literal('Guinea'),
  z.literal('Guinea-Bissau'),
  z.literal('Guyana'),
  z.literal('Haiti'),
  z.literal('Heard Island and McDonald Islands'),
  z.literal('Holy See (Vatican City State)'),
  z.literal('Honduras'),
  z.literal('Hong Kong'),
  z.literal('Hungary'),
  z.literal('Iceland'),
  z.literal('India'),
  z.literal('Indonesia'),
  z.literal('Iran, Islamic Republic of'),
  z.literal('Iraq'),
  z.literal('Ireland'),
  z.literal('Isle of Man'),
  z.literal('Israel'),
  z.literal('Italy'),
  z.literal('Jamaica'),
  z.literal('Japan'),
  z.literal('Jersey'),
  z.literal('Jordan'),
  z.literal('Kazakhstan'),
  z.literal('Kenya'),
  z.literal('Kiribati'),
  z.literal('Korea, Democratic People&#39;s Republic of'),
  z.literal('Korea, Republic of'),
  z.literal('Kuwait'),
  z.literal('Kyrgyzstan'),
  z.literal('Lao People&#39;s Democratic Republic'),
  z.literal('Latvia'),
  z.literal('Lebanon'),
  z.literal('Lesotho'),
  z.literal('Liberia'),
  z.literal('Libyan Arab Jamahiriya'),
  z.literal('Liechtenstein'),
  z.literal('Lithuania'),
  z.literal('Luxembourg'),
  z.literal('Macao'),
  z.literal('Macedonia, the former Yugoslav Republic of'),
  z.literal('Madagascar'),
  z.literal('Malawi'),
  z.literal('Malaysia'),
  z.literal('Maldives'),
  z.literal('Mali'),
  z.literal('Malta'),
  z.literal('Martinique'),
  z.literal('Mauritania'),
  z.literal('Mauritius'),
  z.literal('Mayotte'),
  z.literal('Mexico'),
  z.literal('Moldova, Republic of'),
  z.literal('Monaco'),
  z.literal('Mongolia'),
  z.literal('Montenegro'),
  z.literal('Montserrat'),
  z.literal('Morocco'),
  z.literal('Mozambique'),
  z.literal('Myanmar'),
  z.literal('Namibia'),
  z.literal('Nauru'),
  z.literal('Nepal'),
  z.literal('Netherlands'),
  z.literal('New Caledonia'),
  z.literal('New Zealand'),
  z.literal('Nicaragua'),
  z.literal('Niger'),
  z.literal('Nigeria'),
  z.literal('Niue'),
  z.literal('Norfolk Island'),
  z.literal('Norway'),
  z.literal('Oman'),
  z.literal('Pakistan'),
  z.literal('Palestinian Territory, Occupied'),
  z.literal('Panama'),
  z.literal('Papua New Guinea'),
  z.literal('Paraguay'),
  z.literal('Peru'),
  z.literal('Philippines'),
  z.literal('Pitcairn'),
  z.literal('Poland'),
  z.literal('Portugal'),
  z.literal('Qatar'),
  z.literal('Reunion'),
  z.literal('Romania'),
  z.literal('Russia'),
  z.literal('Russian Federation'),
  z.literal('Rwanda'),
  z.literal('Saint Barthélemy'),
  z.literal('Saint Helena, Ascension and Tristan da Cunha'),
  z.literal('Saint Kitts and Nevis'),
  z.literal('Saint Lucia'),
  z.literal('Saint Martin (French part)'),
  z.literal('Saint Pierre and Miquelon'),
  z.literal('Saint Vincent and the Grenadines'),
  z.literal('Samoa'),
  z.literal('San Marino'),
  z.literal('Sao Tome and Principe'),
  z.literal('Saudi Arabia'),
  z.literal('Senegal'),
  z.literal('Serbia'),
  z.literal('Seychelles'),
  z.literal('Sierra Leone'),
  z.literal('Singapore'),
  z.literal('Sint Maarten (Dutch part)'),
  z.literal('Slovakia'),
  z.literal('Slovenia'),
  z.literal('Solomon Islands'),
  z.literal('Somalia'),
  z.literal('South Africa'),
  z.literal('South Georgia and the South Sandwich Islands'),
  z.literal('South Sudan'),
  z.literal('Spain'),
  z.literal('Sri Lanka'),
  z.literal('Sudan'),
  z.literal('Suriname'),
  z.literal('Svalbard and Jan Mayen'),
  z.literal('Swaziland'),
  z.literal('Sweden'),
  z.literal('Switzerland'),
  z.literal('Syrian Arab Republic'),
  z.literal('Tajikistan'),
  z.literal('Tanzania, United Republic of'),
  z.literal('Thailand'),
  z.literal('Timor-Leste'),
  z.literal('Togo'),
  z.literal('Tokelau'),
  z.literal('Tonga'),
  z.literal('Trinidad and Tobago'),
  z.literal('Tunisia'),
  z.literal('Turkey'),
  z.literal('Turkmenistan'),
  z.literal('Turks and Caicos Islands'),
  z.literal('Tuvalu'),
  z.literal('Uganda'),
  z.literal('Ukraine'),
  z.literal('United Arab Emirates'),
  z.literal('United Kingdom'),
  z.literal('United States'),
  z.literal('Uruguay'),
  z.literal('Uzbekistan'),
  z.literal('Vanuatu'),
  z.literal('Venezuela, Bolivarian Republic of'),
  z.literal('Viet Nam'),
  z.literal('Virgin Islands, British'),
  z.literal('Wallis and Futuna'),
  z.literal('Western Sahara'),
  z.literal('Yemen'),
  z.literal('Zambia'),
  z.literal('Zimbabwe'),
])

export type CountryDto = z.infer<typeof CountryDtoSchema>
