import { Box, Button, Card, CardMedia, Stack, styled, Typography } from '@mui/material'
import DOMPurify from 'dompurify'
import type { FC } from 'react'

import { isNotNull } from '@hcr/utils'

import { COLORS } from '../../../../constants'
import type { Link } from '../../../../models'
import { optimizelyCss } from '../../../../styles'
import { createLinkExternalProps, unit } from '../../../../utils'
import { LinkUnstyled } from '../../../navigation'
import type { CardVariant } from '../types'
import { CardEmbedment } from '../types'

import {
  cardEmbedmentCss,
  cardVariantCss,
  CONTENT_GAP,
  CONTENT_PADDING,
  IMAGE_HEIGHT,
  overflowGradientVariantCss,
} from './styles'

export interface CardPrimaryProps {
  badge: string | null
  description: string
  embedment: CardEmbedment
  imageUrl: string | null
  link: Link | null
  title: string | null
  variant: CardVariant
}

export const CardPrimary: FC<CardPrimaryProps> = ({
  badge,
  description,
  embedment,
  imageUrl,
  link,
  title,
  variant,
}) => {
  return (
    <Card css={[cardEmbedmentCss[embedment], cardVariantCss[variant]]}>
      {isNotNull(imageUrl) && (
        <CardMedia image={imageUrl} sx={{ height: unit(IMAGE_HEIGHT) }}>
          {isNotNull(badge) && (
            <Box position='relative'>
              <Badge>
                <Typography
                  variant='bodyM'
                  color={COLORS.white}
                  css={optimizelyCss}
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(badge) }}
                />
              </Badge>
            </Box>
          )}
        </CardMedia>
      )}
      <Stack
        gap={unit(CONTENT_GAP)}
        padding={unit(CONTENT_PADDING)}
        height={isNotNull(imageUrl) ? `calc(100% - ${unit(IMAGE_HEIGHT)})` : '100%'}
      >
        {isNotNull(title) && <Typography variant='titleM'>{title}</Typography>}
        <Box position='relative' overflow='hidden' flexGrow={1}>
          <Typography
            variant='bodyM'
            css={optimizelyCss}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
          />
          {embedment === CardEmbedment.Carousel && (
            <Box position='absolute' top={0} right={0} bottom={0} left={0} css={overflowGradientVariantCss[variant]} />
          )}
        </Box>
        {isNotNull(link) && (
          <Button
            component={LinkUnstyled}
            to={link.to}
            {...createLinkExternalProps(link.isExternal)}
            variant='outlined'
            color='black'
          >
            {link.label}
          </Button>
        )}
      </Stack>
    </Card>
  )
}

const Badge = styled(Box)`
  position: absolute;
  right: ${unit(3)};
  top: ${unit(3)};
  height: ${unit(9.5)};
  width: fit-content;
  display: flex;
  align-items: center;
  padding-inline: ${unit(3.25)};
  border-radius: ${unit(1)};
  background-color: ${COLORS.burgundi[500]};
`
