import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { filter, find, flow, getPropertyValue, isEmpty, isNotNull, isNotUndefined, isUndefined, sort } from '@hcr/utils'

import { useDestinationDetailsQuery, useDestinationsQuery, useIdToken, useSingleTicketsQuery } from '../../../../hooks'
import { DateTimeFormat, Path } from '../../../../models'
import {
  compareDates,
  createSingleTicketNameString,
  createSingleTicketsGuestsString,
  dateFormatter,
  isDestinationOf,
  isSingleTicketIdIn,
  to,
} from '../../../../utils'
import { ButtonBuyTicket, LayoutNavigationBackError500, LayoutNavigationBackLoading } from '../../../common'
import { LayoutTicketDetailsActive, TicketValidityActive } from '../common'

interface TicketDetailsSingleActiveBundleProps {
  resortsIds: number[]
  ticketsIds: number[]
}

const BACK_NAVIGATION_PATH = to(Path.Booking)

export const TicketDetailsSingleActiveBundle: FC<TicketDetailsSingleActiveBundleProps> = ({
  resortsIds,
  ticketsIds,
}) => {
  const { t } = useTranslation()
  const idToken = useIdToken()

  const singleTickets = useSingleTicketsQuery(
    { idToken: String(idToken), resortsIds },
    {
      select: flow(
        filter(isSingleTicketIdIn(ticketsIds)),
        sort({ comparer: compareDates, desc: getPropertyValue('activation_date') })
      ),
      enabled: isNotNull(idToken),
    }
  )

  const destination = useDestinationsQuery({
    select: flow(getPropertyValue('destinations'), find(isDestinationOf(singleTickets.data?.[0]))),
    enabled: isNotUndefined(singleTickets.data?.[0]),
  })

  const destinationDetails = useDestinationDetailsQuery(
    { destinationId: String(destination.data?.destinationId) },
    { enabled: isNotUndefined(destination.data?.destinationId) }
  )

  if (
    singleTickets.isError ||
    (singleTickets.isSuccess && isEmpty(singleTickets.data)) ||
    destination.isError ||
    (destination.isSuccess && isUndefined(destination.data)) ||
    destinationDetails.isError
  ) {
    return <LayoutNavigationBackError500 to={BACK_NAVIGATION_PATH} />
  }

  if (singleTickets.isSuccess && destination.isSuccess && destinationDetails.isSuccess) {
    if (isNotNull(singleTickets.data[0].activation_date)) {
      return (
        <LayoutTicketDetailsActive
          additionalInfo={[
            { label: t('tickets.resort'), value: destinationDetails.data.name },
            { label: t('tickets.booking-numbers'), value: singleTickets.data.map(getPropertyValue('ticket_id')) },
          ]}
          backNavigationPath={BACK_NAVIGATION_PATH}
          cta={<ButtonBuyTicket resortId={singleTickets.data[0].resort_id} variant='outlined' color='black' />}
          destinationLogoUrl={destinationDetails.data.logoUrl}
          destinationName={destinationDetails.data.name}
          guests={createSingleTicketsGuestsString(singleTickets.data)}
          name={createSingleTicketNameString(singleTickets.data[0])}
          validity={t('tickets.enjoy-your-visit')}
        >
          <TicketValidityActive>
            {dateFormatter.format(DateTimeFormat.Standard, singleTickets.data[0].activation_date)}
          </TicketValidityActive>
        </LayoutTicketDetailsActive>
      )
    }

    return <LayoutNavigationBackError500 to={BACK_NAVIGATION_PATH} />
  }

  return <LayoutNavigationBackLoading to={BACK_NAVIGATION_PATH} />
}
