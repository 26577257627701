import { useEffect, useRef } from 'react'
import { useAnalytics } from 'use-analytics'

import { isNotNull, isNotUndefined, isUndefined } from '@hcr/utils'

import { useHoliday } from '../../../contexts'
import { createAccommodationContextChangedEvent } from '../../../utils'

export const useAccommodationContextChangeTracking = (): void => {
  const {
    data: { accommodation },
    ...holiday
  } = useHoliday()
  const analytics = useAnalytics()
  const accommodationIdRef = useRef<number | null | undefined>(undefined)

  useEffect(() => {
    const trackAccommodationContextChange = () => {
      if (holiday.isSuccess && isUndefined(accommodation) && isNotNull(accommodationIdRef.current)) {
        accommodationIdRef.current = null
        return analytics.track(...createAccommodationContextChangedEvent({ accommodationId: null }))
      }

      if (isNotUndefined(accommodation) && accommodation.accommodation_id !== accommodationIdRef.current) {
        accommodationIdRef.current = accommodation.accommodation_id
        return analytics.track(
          ...createAccommodationContextChangedEvent({
            accommodationId: accommodation.accommodation_id,
            resortId: accommodation.resort_id,
            fromDate: accommodation.start_date,
            toDate: accommodation.end_date,
          })
        )
      }
    }

    void trackAccommodationContextChange()
  }, [holiday.isSuccess, analytics, accommodation])
}
