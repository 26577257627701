import hash from 'object-hash'
import type { ReactNode } from 'react'

import type { ActivityDto, PackageTicketDto, SingleTicketDto } from '@hcr/api/consumer'
import { isActivityDto, isPackageTicketDto, isSingleTicketDto } from '@hcr/api/consumer'

import { TicketPreviewActivity } from './TicketPreviewActivity'
import { TicketPreviewPackage } from './TicketPreviewPackage'
import { TicketPreviewSingleBundle } from './TicketPreviewSingleBundle'
import { TicketPreviewSingleOne } from './TicketPreviewSingleOne'

export const createTicketPreview = () => {
  return (dto: ActivityDto | PackageTicketDto | SingleTicketDto | SingleTicketDto[]): ReactNode => {
    if (isActivityDto(dto)) {
      return <TicketPreviewActivity key={dto.activity_id} activity={dto} />
    }

    if (isPackageTicketDto(dto)) {
      return <TicketPreviewPackage key={hash(['package', dto.category_id, dto.status])} ticket={dto} />
    }

    if (isSingleTicketDto(dto)) {
      return <TicketPreviewSingleOne key={dto.ticket_id} ticket={dto} />
    }

    return (
      <TicketPreviewSingleBundle
        key={hash(['single-bundle', dto[0].category_id, dto[0].status, dto[0].timeslot])}
        tickets={dto}
      />
    )
  }
}
