import { t } from 'i18next'

import { isActivityNameDto, type ActivityDto } from '@hcr/api/consumer'

import { LocaleNamespace } from '../../models'

type CreateActivityNameStringOptions = Pick<ActivityDto, 'name'>

export const createActivityNameString = ({ name }: CreateActivityNameStringOptions): string =>
  isActivityNameDto(name) ? t(name, { ns: LocaleNamespace.ActivityName }) : name
