import { Button, Stack, styled, Typography } from '@mui/material'
import type { FC, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { COLORS, LinkUnstyled, unit } from '@hcr/ui'

import { useHoliday } from '../../../../../contexts'
import { EMPTY_PATH_PARAM, Path } from '../../../../../models'
import { to } from '../../../../../utils'
import { TimeshareIntroductionBox } from '../../../../common'

type LayoutTicketsProps = PropsWithChildren

export const LayoutTickets: FC<LayoutTicketsProps> = ({ children }) => {
  const { t } = useTranslation()
  const {
    data: { destination },
  } = useHoliday()

  return (
    <>
      {children}

      <TimeshareIntroductionBox />

      <Footer>
        <Typography variant='titleM'>{t('tickets.looking-for-activities?')}</Typography>
        <Typography variant='bodyM'>{t('tickets.explore-our-services-and-find-something-to-do')}</Typography>
        <Button
          component={LinkUnstyled}
          to={to([Path.Services, { destinationId: destination?.destinationId ?? EMPTY_PATH_PARAM }])}
          variant='outlined'
          color='black'
        >
          {t('tickets.explore-services')}
        </Button>
      </Footer>
    </>
  )
}

const Footer = styled(Stack)`
  align-items: center;
  gap: ${unit(3)};
  padding: ${unit(7)} ${unit(4)} ${unit(5)};
  background-color: ${COLORS.hiekka[500]};
`
