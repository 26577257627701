import type { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import type { ActivityDto, SingleTicketDto } from '@hcr/api/consumer'
import { find, flow, getPropertyValue, isNotUndefined } from '@hcr/utils'

import { useHoliday } from '../../../../../contexts'
import { useDestinationsQuery } from '../../../../../hooks'
import { Path } from '../../../../../models'
import { isDestinationOf, to } from '../../../../../utils'
import type { BookingListPreview } from '../../types'

interface BookingListPreviewTicketProps {
  component: BookingListPreview
  ticket: SingleTicketDto | ActivityDto
}

export const BookingListPreviewTicket: FC<BookingListPreviewTicketProps> = ({ component: Component, ticket }) => {
  const {
    data: { destination: holidayDestination },
    ...holiday
  } = useHoliday()
  const navigate = useNavigate()

  const destination = useDestinationsQuery({
    select: flow(getPropertyValue('destinations'), find(isDestinationOf(ticket))),
  })

  if (destination.isSuccess && isNotUndefined(destination.data)) {
    return (
      <Component
        destinationId={destination.data.destinationId}
        destinationName={destination.data.title}
        isActive={destination.data.destinationId === holidayDestination?.destinationId}
        onClick={() => {
          isNotUndefined(destination.data) && holiday.setDestination(destination.data)
          navigate(to(Path.Booking))
        }}
      />
    )
  }

  return null
}
