import { Button, Stack } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import type { FC } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { createSingleTicketsQueryKey } from '@hcr/api/consumer'
import { Alert, ButtonLoading, InformationBanner, unit } from '@hcr/ui'
import {
  find,
  flow,
  getPropertyValue,
  hasPropertyValue,
  isNotNull,
  isNotUndefined,
  isUndefined,
  logger,
} from '@hcr/utils'

import { SvgPhoneInHand } from '../../../../assets'
import {
  useDestinationsQuery,
  useIdToken,
  useSingleTicketActivationMutation,
  useSingleTicketsQuery,
} from '../../../../hooks'
import type { TicketDetailsSingleSearchParams } from '../../../../models'
import { DateFormat, LocaleNamespace, Path, TicketDetailsSingleSearchParamsKeys } from '../../../../models'
import {
  createSingleTicketNameString,
  createSingleTicketValidityString,
  currencyFormatter,
  dateFormatter,
  isDestinationOf,
  to,
} from '../../../../utils'
import { ButtonBuyTicket, LayoutNavigationBackError500, LayoutNavigationBackLoading } from '../../../common'
import { LayoutTicketDetailsInactive } from '../common'

interface TicketDetailsSingleInactiveOneProps {
  resortsIds: number[]
  ticketId: number
}

const BACK_NAVIGATION_PATH = to(Path.Booking)
const STATUS_ACTIVE_SEARCH_PARAM: TicketDetailsSingleSearchParams[TicketDetailsSingleSearchParamsKeys.Status] = 'Active'

export const TicketDetailsSingleInactiveOne: FC<TicketDetailsSingleInactiveOneProps> = ({ resortsIds, ticketId }) => {
  const { t } = useTranslation()
  const idToken = useIdToken()
  const [searchParams, setSearchParams] = useSearchParams()
  const queryClient = useQueryClient()

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)

  const openConfirmation = () => setIsConfirmationOpen(true)
  const cancelConfirmation = () => setIsConfirmationOpen(false)

  const singleTicket = useSingleTicketsQuery(
    { idToken: String(idToken), resortsIds },
    {
      select: find(hasPropertyValue('ticket_id', ticketId)),
      enabled: isNotNull(idToken),
    }
  )

  const destination = useDestinationsQuery({
    select: flow(getPropertyValue('destinations'), find(isDestinationOf(singleTicket.data))),
    enabled: isNotUndefined(singleTicket.data),
  })

  const singleTicketActivation = useSingleTicketActivationMutation({ idToken: String(idToken) })

  const handleActivation = async () => {
    try {
      await singleTicketActivation.mutateAsync({ ticketId })
      queryClient.removeQueries({ queryKey: createSingleTicketsQueryKey() })

      const updatedSearchParams = new URLSearchParams(searchParams)
      updatedSearchParams.set(TicketDetailsSingleSearchParamsKeys.Status, STATUS_ACTIVE_SEARCH_PARAM)
      return setSearchParams(updatedSearchParams)
    } catch {
      logger.error('Failed to activate the ticket:', singleTicket.data)
      cancelConfirmation()
    }
  }

  if (singleTicket.isError || (singleTicket.isSuccess && isUndefined(singleTicket.data))) {
    return <LayoutNavigationBackError500 to={BACK_NAVIGATION_PATH} />
  }

  if (singleTicket.isSuccess && isNotUndefined(singleTicket.data)) {
    return (
      <LayoutTicketDetailsInactive
        additionalInfo={[
          ...(isNotUndefined(destination.data) ? [{ label: t('tickets.resort'), value: destination.data.title }] : []),
          { label: t('tickets.price'), value: currencyFormatter.format(singleTicket.data.price) },
          {
            label: t('tickets.booking-date'),
            value: dateFormatter.format(DateFormat.Standard, singleTicket.data.activation_possible_from_date),
          },
          { label: t('tickets.booking-number'), value: ticketId },
        ]}
        backNavigationPath={BACK_NAVIGATION_PATH}
        cta={<ButtonBuyTicket resortId={singleTicket.data.resort_id} variant='outlined' color='black' />}
        guests={t(singleTicket.data.ticket_group, { ns: LocaleNamespace.TicketGroup })}
        name={createSingleTicketNameString(singleTicket.data)}
        type={t('single-one', { ns: LocaleNamespace.TicketType })}
        validity={createSingleTicketValidityString(singleTicket.data)}
      >
        <Stack gap={unit(1.5)} marginTop={unit(5.5)}>
          <InformationBanner
            header={t('tickets.how-to-use')}
            description={t('tickets.how-to-use-single-one')}
            icon={<SvgPhoneInHand />}
          />
          {singleTicketActivation.isError && (
            <Alert
              severity='error'
              title={t('tickets.activation-failed')}
              description={t('tickets.an-error-occurred-while-activating-single-one')}
            />
          )}
          {isConfirmationOpen ? (
            <>
              <ButtonLoading
                onClick={handleActivation}
                isLoading={singleTicketActivation.isPending}
                variant='contained'
                color='success'
              >
                {t('tickets.confirm')}
              </ButtonLoading>
              <Button
                onClick={cancelConfirmation}
                disabled={singleTicketActivation.isPending}
                variant='outlined'
                color='black'
              >
                {t('tickets.cancel')}
              </Button>
            </>
          ) : (
            <Button onClick={openConfirmation} variant='contained' color='primary'>
              {t('tickets.activate-ticket')}
            </Button>
          )}
        </Stack>
      </LayoutTicketDetailsInactive>
    )
  }

  return <LayoutNavigationBackLoading to={BACK_NAVIGATION_PATH} />
}
