import { Stack, Typography } from '@mui/material'
import DOMPurify from 'dompurify'
import type { FC, RefCallback } from 'react'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { clampCss, optimizelyCss, unit } from '@hcr/ui'
import { isNull } from '@hcr/utils'

interface ExpandableTextProps {
  lineClamp?: number
  value: string
}

export const ExpandableText: FC<ExpandableTextProps> = ({ lineClamp = 4, value }) => {
  const { t } = useTranslation()
  const [isExpanded, setIsExpanded] = useState(false)
  const [hasEllipsis, setHasEllipsis] = useState(false)

  const contentRef = useCallback<RefCallback<HTMLSpanElement>>(element => {
    if (isNull(element)) {
      return
    }

    setHasEllipsis(element.offsetHeight < element.scrollHeight)
  }, [])

  return (
    <Stack direction='row' flexWrap='wrap'>
      <Typography
        variant='bodyM'
        lineHeight={unit(6)}
        sx={{ wordBreak: 'break-word' }}
        css={[optimizelyCss, !isExpanded && clampCss(lineClamp)]}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value) }}
        ref={contentRef}
      />
      {hasEllipsis && (
        <Typography variant='textLinkM' onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? t('expandable-text.read-less') : t('expandable-text.read-more')}
        </Typography>
      )}
    </Stack>
  )
}
