import { Box, CardMedia, Stack, Typography } from '@mui/material'
import DOMPurify from 'dompurify'
import type { FC } from 'react'
import { useParams } from 'react-router-dom'

import { COLORS, hideEmptyCss, optimizelyCss, unit } from '@hcr/ui'
import { isNotNull, isNotUndefined, isUndefined } from '@hcr/utils'

import { useOwnerDetailsQuery } from '../../../../hooks'
import type { OwnerDetailsPathParams } from '../../../../models'
import { Path } from '../../../../models'
import { createLink, createOwnerDetailsContent, to } from '../../../../utils'
import { LayoutCta, LayoutNavigationBack, LayoutNavigationBackError404 } from '../../../common'

import { OwnerDetailsLoading } from './OwnerDetailsLoading'

const BACK_NAVIGATION_PATH = to(Path.Owner)

const ownerDetailsContent = createOwnerDetailsContent()

export const OwnerDetails: FC = () => {
  const params = useParams<OwnerDetailsPathParams>()

  const ownerDetails = useOwnerDetailsQuery(
    { ownerId: String(params.ownerId) },
    { enabled: isNotUndefined(params.ownerId) }
  )

  if (isUndefined(params.ownerId) || ownerDetails.isError) {
    return <LayoutNavigationBackError404 to={BACK_NAVIGATION_PATH} />
  }

  if (ownerDetails.isSuccess) {
    return (
      <LayoutNavigationBack to={BACK_NAVIGATION_PATH} arrowVariant='outlined'>
        <Box bgcolor={COLORS.white} minHeight='100vh'>
          <LayoutCta link={isNotNull(ownerDetails.data.link) ? createLink(ownerDetails.data.link) : null}>
            <CardMedia image={ownerDetails.data.imageUrl} sx={{ height: unit(71) }} />
            <Stack gap={unit(4)} paddingY={unit(9)} paddingX={unit(8)}>
              <Typography variant='headlineL'>{ownerDetails.data.title}</Typography>
              {isNotNull(ownerDetails.data.description) && (
                <Typography
                  variant='bodyM'
                  css={optimizelyCss}
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(ownerDetails.data.description) }}
                />
              )}
            </Stack>
            <Stack gap={unit(3)} paddingX={unit(3)} paddingBottom={unit(3)} css={hideEmptyCss}>
              {ownerDetails.data.content.map(ownerDetailsContent)}
            </Stack>
          </LayoutCta>
        </Box>
      </LayoutNavigationBack>
    )
  }

  return <OwnerDetailsLoading backNavigationPath={BACK_NAVIGATION_PATH} />
}
