import { css, styled, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import DOMPurify from 'dompurify'
import hash from 'object-hash'
import type { FC } from 'react'

import { isNotNull } from '@hcr/utils'

import { COLORS } from '../../../constants'
import { optimizelyCss } from '../../../styles'
import { unit } from '../../../utils'

interface PricingProps {
  content: Array<{
    label: string
    value: string
  }>
  description: string | null
  footer: string | null
  title: string
}

export const Pricing: FC<PricingProps> = ({ content, description, footer, title }) => {
  return (
    <TableContainer>
      <TableHead>
        <TableRow>
          <TableCellColumnar>
            <Typography variant='uppercaseL' marginTop={unit(5)} color={COLORS.burgundi[500]}>
              {title}
            </Typography>
            {isNotNull(description) && <Typography variant='meta'>{description}</Typography>}
          </TableCellColumnar>
        </TableRow>
      </TableHead>
      <TableBody>
        {content.map(({ label, value }) => (
          <TableRowBordered key={hash([label, value])}>
            <TableCellJustified>
              <Typography variant='labelL'>{label}</Typography>
              <Typography
                variant='bodyL'
                textAlign='right'
                css={[optimizelyCss, priceCss]}
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value) }}
              />
            </TableCellJustified>
          </TableRowBordered>
        ))}
        {isNotNull(footer) && (
          <TableRowBordered>
            <TableCellBase>
              <Typography
                variant='bodyL'
                css={optimizelyCss}
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(footer) }}
              />
            </TableCellBase>
          </TableRowBordered>
        )}
      </TableBody>
    </TableContainer>
  )
}

const TableContainer = styled(Table)`
  background-color: ${COLORS.vaalea['60%']};
`

const TableRowBordered = styled(TableRow)`
  border-top: thin solid ${COLORS.semantic.divider};
`

const TableCellBase = styled(TableCell)`
  padding-left: ${unit(5)};
  padding-right: ${unit(5)};
`

const TableCellColumnar = styled(TableCellBase)`
  display: flex;
  flex-direction: column;
`

const TableCellJustified = styled(TableCellBase)`
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
`

const priceCss = css`
  em {
    color: ${COLORS.burgundi[500]};
  }
`
