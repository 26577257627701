import { Button } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { type PriceCategoryIdDto } from '@hcr/api/consumer'
import { LinkUnstyled } from '@hcr/ui'
import { find, flow, getPropertyValue, isNotNull, isNotUndefined, isUndefined } from '@hcr/utils'

import {
  useAccommodationDetailsQuery,
  useDestinationDetailsQuery,
  useDestinationsQuery,
  useIdToken,
} from '../../../../hooks'
import { Path } from '../../../../models'
import {
  createAccommodationGuestsString,
  createPackageTicketValidityString,
  createSingleTicketNameString,
  dateFormatter,
  isDestinationOf,
  to,
} from '../../../../utils'
import { LayoutNavigationBackError500, LayoutNavigationBackLoading } from '../../../common'
import { LayoutTicketDetailsActive, TicketValidityActive } from '../common'

interface TicketDetailsPackageActiveProps {
  accommodationId: number
  categoryId: PriceCategoryIdDto | string
}

const BACK_NAVIGATION_PATH = to(Path.Booking)

export const TicketDetailsPackageActive: FC<TicketDetailsPackageActiveProps> = ({ accommodationId, categoryId }) => {
  const { t } = useTranslation()
  const idToken = useIdToken()

  const accommodation = useAccommodationDetailsQuery(
    { idToken: String(idToken), accommodationId },
    { enabled: isNotNull(idToken) }
  )

  const destination = useDestinationsQuery({
    select: flow(getPropertyValue('destinations'), find(isDestinationOf(accommodation.data))),
    enabled: isNotUndefined(accommodation.data),
  })

  const destinationDetails = useDestinationDetailsQuery(
    { destinationId: String(destination.data?.destinationId) },
    { enabled: isNotUndefined(destination.data?.destinationId) }
  )

  if (
    accommodation.isError ||
    destination.isError ||
    (destination.isSuccess && isUndefined(destination.data)) ||
    destinationDetails.isError
  ) {
    return <LayoutNavigationBackError500 to={BACK_NAVIGATION_PATH} />
  }

  if (accommodation.isSuccess && destination.isSuccess && destinationDetails.isSuccess) {
    return (
      <LayoutTicketDetailsActive
        additionalInfo={[{ label: t('tickets.resort'), value: destinationDetails.data.name }]}
        backNavigationPath={BACK_NAVIGATION_PATH}
        cta={
          <Button component={LinkUnstyled} to={BACK_NAVIGATION_PATH} variant='outlined' color='black'>
            {t('tickets.back-to-booking')}
          </Button>
        }
        destinationLogoUrl={destinationDetails.data.logoUrl}
        destinationName={destinationDetails.data.name}
        guests={createAccommodationGuestsString(accommodation.data)}
        name={createSingleTicketNameString({ category_id: categoryId, timeslot: null })}
        validity={createPackageTicketValidityString('Active')}
      >
        <TicketValidityActive>
          {dateFormatter.formatDateRange({ from: accommodation.data.start_date, to: accommodation.data.end_date })}
        </TicketValidityActive>
      </LayoutTicketDetailsActive>
    )
  }

  return <LayoutNavigationBackLoading to={BACK_NAVIGATION_PATH} />
}
