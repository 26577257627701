import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { type ActivityDto } from '@hcr/api/consumer'
import { filter, flow, isNotNull, isNotUndefined, isSame, unique } from '@hcr/utils'

import { useHoliday } from '../../../../../contexts'
import { useDestinationDetailsQuery } from '../../../../../hooks'
import { LocaleNamespace, Path } from '../../../../../models'
import { createActivityNameString, createActivityValidity, to } from '../../../../../utils'

import { TicketPreview } from './TicketPreview'
import { TicketPreviewLoading } from './TicketPreviewLoading'

interface TicketPreviewActivityProps {
  activity: ActivityDto
}

export const TicketPreviewActivity: FC<TicketPreviewActivityProps> = ({ activity }) => {
  const { t } = useTranslation()
  const {
    data: { destination },
    ...holiday
  } = useHoliday()

  const resortsIds = useDestinationDetailsQuery(
    { destinationId: String(destination?.destinationId) },
    {
      select: flow(
        destinationDetails => [destinationDetails.hotelId, destinationDetails.mokkiId],
        filter(isNotNull),
        unique(isSame)
      ),
      enabled: isNotUndefined(destination),
      retryOnMount: false,
    }
  )

  if (holiday.isError || resortsIds.isError) {
    return null
  }

  if (holiday.isSuccess && resortsIds.isSuccess) {
    return (
      <TicketPreview
        key={activity.activity_id}
        link={to([
          Path.TicketDetailsActivity,
          { activityId: activity.activity_id },
          { resortsIds: resortsIds.data.join(',') },
        ])}
        name={createActivityNameString(activity)}
        status={activity.status}
        type={t('activity', { ns: LocaleNamespace.TicketType })}
        validity={createActivityValidity(activity).date}
      />
    )
  }

  return <TicketPreviewLoading />
}
