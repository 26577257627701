import { Button, Stack, styled, Typography } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { COLORS, LinkUnstyled, unit } from '@hcr/ui'

import { useHoliday } from '../../../contexts'
import { EMPTY_PATH_PARAM, Path } from '../../../models'
import { to } from '../../../utils'

export const TicketsEmpty: FC = () => {
  const { t } = useTranslation()
  const {
    data: { destination },
  } = useHoliday()

  return (
    <Container>
      <Typography variant='bodyM' textAlign='center'>
        {t('tickets.no-tickets')}
      </Typography>
      <Button
        component={LinkUnstyled}
        to={to([Path.Services, { destinationId: destination?.destinationId ?? EMPTY_PATH_PARAM }])}
        variant='outlined'
        color='black'
      >
        {t('tickets.explore-services')}
      </Button>
    </Container>
  )
}

const Container = styled(Stack)`
  gap: ${unit(4)};
  padding: ${unit(6)} ${unit(4)};
  border: ${unit(0.5)} dashed ${COLORS.semantic.divider};
  width: 100%;
`
