import { LogLevel } from '@hcr/utils'

import { LocaleId } from '../models'

import type { EnvironmentVariables } from './types'

export const Environment: EnvironmentVariables = {
  API: {
    Auth: {
      clientId: 'be046ba6-0415-47d9-8cd8-163a4f0f477b',
      domain: 'hcrcustomerb2c.b2clogin.com',
      redirectUrl: 'https://demo.loma.holidayclubresorts.com',
      url: 'https://hcrcustomerb2c.b2clogin.com/hcrcustomerb2c.onmicrosoft.com/B2C_1_pwa_signin',
    },
    Consumer: {
      url: 'https://consumer-api.com/mocks',
      useMocks: true,
    },
    Optimizely: {
      url: 'https://www.holidayclubresorts.com/api/v1/mobile',
      useMocks: false,
    },
    Owners: {
      url: 'https://osakaspalvelu.holidayclubresorts.com',
    },
    Webshop: {
      url: 'https://www.holidayclubresorts.com',
    },
  },
  Analytics: {
    useAnalytics: false,
  },
  General: {
    localeId: LocaleId.Fi,
    logLevel: LogLevel.Log,
    name: 'demo',
  },
  Gomeddo: {
    business: 'hcr',
    page: '2a5ac0d8-0519-43cd-a28d-8cc5b686d43a',
  },
  Sentry: {
    useSentry: false,
  },
  TermsOfCookies: {
    url: {
      [LocaleId.EnUS]: `https://www.holidayclubresorts.com/en/footermenu/our-web-service/data-protection/cookie-policy/`,
      [LocaleId.Fi]: `https://www.holidayclubresorts.com/fi/footermenu/verkkopalvelustamme/tietosuoja/evasteseloste/`,
    },
  },
}
