import hash from 'object-hash'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { type SingleTicketDto } from '@hcr/api/consumer'
import { ICONS } from '@hcr/ui'
import { filter, flow, isNotNull, isNotUndefined, isSame, unique } from '@hcr/utils'

import { useHoliday } from '../../../../../contexts'
import { useDestinationDetailsQuery } from '../../../../../hooks'
import { LocaleNamespace, Path } from '../../../../../models'
import {
  createSingleTicketNameString,
  createSingleTicketsGuestsString,
  createSingleTicketValidityString,
  to,
} from '../../../../../utils'

import { TicketPreview } from './TicketPreview'
import { TicketPreviewLoading } from './TicketPreviewLoading'

interface TicketPreviewSingleBundleProps {
  tickets: SingleTicketDto[]
}

export const TicketPreviewSingleBundle: FC<TicketPreviewSingleBundleProps> = ({ tickets }) => {
  const { t } = useTranslation()
  const {
    data: { destination },
    ...holiday
  } = useHoliday()

  const resortsIds = useDestinationDetailsQuery(
    { destinationId: String(destination?.destinationId) },
    {
      select: flow(
        destinationDetails => [destinationDetails.hotelId, destinationDetails.mokkiId],
        filter(isNotNull),
        unique(isSame)
      ),
      enabled: isNotUndefined(destination),
      retryOnMount: false,
    }
  )

  if (holiday.isError || resortsIds.isError) {
    return null
  }

  if (holiday.isSuccess && resortsIds.isSuccess) {
    return (
      <TicketPreview
        key={hash(['single-bundle', tickets[0].category_id, tickets[0].status])}
        guests={createSingleTicketsGuestsString(tickets)}
        guestsIcon={ICONS.farLayerGroup}
        link={to([
          Path.TicketDetailsSingle,
          { categoryId: tickets[0].category_id },
          {
            resortsIds: resortsIds.data.join(','),
            status: tickets[0].status,
            timeslot: tickets[0].timeslot ?? undefined,
          },
        ])}
        name={createSingleTicketNameString(tickets[0])}
        status={tickets[0].status}
        type={t('single-bundle', { ns: LocaleNamespace.TicketType })}
        validity={createSingleTicketValidityString(tickets[0])}
      />
    )
  }

  return <TicketPreviewLoading />
}
