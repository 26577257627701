import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Stack, styled, Typography } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { COLORS, ICONS, unit } from '@hcr/ui'
import { isNotNull, isNotUndefined } from '@hcr/utils'

import { useHoliday } from '../../../contexts'
import { useTimeshareIntroductionInfoQuery, useIdToken } from '../../../hooks'
import { Path } from '../../../models'
import { isFlyAccommodation, to } from '../../../utils'

import { TimeshareIntroductionBoxLoading } from './TimeshareIntroductionBoxLoading'

export const TimeshareIntroductionBox: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const idToken = useIdToken()
  const {
    data: { accommodation },
    ...holiday
  } = useHoliday()

  const hasTimeshareIntroductionInfo = isNotUndefined(accommodation) && isFlyAccommodation(accommodation)

  const timeshareIntroductionInfo = useTimeshareIntroductionInfoQuery({
    enabled: isNotNull(idToken) && hasTimeshareIntroductionInfo,
  })

  if (!hasTimeshareIntroductionInfo) {
    return null
  }

  const onClick = () => {
    return navigate(to(Path.BookingTimeshareIntroduction))
  }

  if (holiday.isSuccess && timeshareIntroductionInfo.isSuccess) {
    return (
      <Stack gap={unit(1.5)} marginY={unit(4)}>
        <Typography variant='uppercaseL'>{t('booking-timeshare-introduction.appointments')}</Typography>

        <Container onClick={onClick}>
          <Stack>
            <Typography variant='headlineExtraSmall'>{timeshareIntroductionInfo.data.title}</Typography>
            <Typography variant='bodyM' paddingTop={unit(4)}>
              {timeshareIntroductionInfo.data.ingress}
            </Typography>
          </Stack>
          <FontAwesomeIcon icon={ICONS.farChevronRight} fontSize={unit(5.5)} color={COLORS.black} />
        </Container>
      </Stack>
    )
  }

  return <TimeshareIntroductionBoxLoading />
}

const Container = styled(Button)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${unit(4)};
  padding: ${unit(5)} ${unit(4)};
  text-align: left;
  background-color: ${COLORS.white};
  color: ${COLORS.black};
  font-weight: normal;
` as typeof Button
