import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { type SingleTicketDto } from '@hcr/api/consumer'
import { filter, flow, isNotNull, isNotUndefined, isSame, unique } from '@hcr/utils'

import { useHoliday } from '../../../../../contexts'
import { useDestinationDetailsQuery } from '../../../../../hooks'
import { LocaleNamespace, Path } from '../../../../../models'
import { createSingleTicketNameString, createSingleTicketValidityString, to } from '../../../../../utils'

import { TicketPreview } from './TicketPreview'
import { TicketPreviewLoading } from './TicketPreviewLoading'

interface TicketPreviewSingleOneProps {
  ticket: SingleTicketDto
}

export const TicketPreviewSingleOne: FC<TicketPreviewSingleOneProps> = ({ ticket }) => {
  const { t } = useTranslation()
  const {
    data: { destination },
    ...holiday
  } = useHoliday()

  const resortsIds = useDestinationDetailsQuery(
    { destinationId: String(destination?.destinationId) },
    {
      select: flow(
        destinationDetails => [destinationDetails.hotelId, destinationDetails.mokkiId],
        filter(isNotNull),
        unique(isSame)
      ),
      enabled: isNotUndefined(destination),
      retryOnMount: false,
    }
  )

  if (holiday.isError || resortsIds.isError) {
    return null
  }

  if (holiday.isSuccess && resortsIds.isSuccess) {
    return (
      <TicketPreview
        key={ticket.ticket_id}
        guests={t(ticket.ticket_group, { ns: LocaleNamespace.TicketGroup })}
        link={to([
          Path.TicketDetailsSingle,
          { categoryId: ticket.category_id },
          { resortsIds: resortsIds.data.join(','), status: ticket.status, timeslot: ticket.timeslot ?? undefined },
        ])}
        name={createSingleTicketNameString(ticket)}
        status={ticket.status}
        type={t('single-one', { ns: LocaleNamespace.TicketType })}
        validity={createSingleTicketValidityString(ticket)}
      />
    )
  }

  return <TicketPreviewLoading />
}
