import { find, hasPropertyValue, isNotNull, isNotUndefined } from '@hcr/utils'

import { useHoldingsQuery, useIdToken } from '../../../../hooks'

interface UseHoldingOptions {
  holdingId: string | undefined
}

export const useHolding = ({ holdingId }: UseHoldingOptions) => {
  const idToken = useIdToken()
  const decodedHoldingId = holdingId ? decodeURIComponent(holdingId) : undefined

  return useHoldingsQuery(
    { idToken: String(idToken) },
    {
      select: find(hasPropertyValue('holding_id', decodedHoldingId)),
      enabled: isNotNull(idToken) && isNotUndefined(decodedHoldingId),
    }
  )
}
