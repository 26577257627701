import type { FC } from 'react'

import { isUndefined } from '@hcr/utils'

import { useHoliday } from '../../../contexts'
import { BookingOverview, LayoutNavigationError500 } from '../../common'

import { BookingEmpty } from './BookingEmpty'
import { LayoutBooking } from './common'

export const Booking: FC = () => {
  const {
    data: { accommodation },
    ...holiday
  } = useHoliday()

  if (holiday.isError) {
    return <LayoutNavigationError500 showContextMenuButton />
  }

  if (holiday.isSuccess && isUndefined(accommodation)) {
    return <BookingEmpty />
  }

  return (
    <LayoutBooking>
      <BookingOverview />
    </LayoutBooking>
  )
}
