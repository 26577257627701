import { Box, Stack, Typography } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { COLORS, unit } from '@hcr/ui'
import { isNotUndefined } from '@hcr/utils'

import { useHoliday } from '../../../contexts'
import { Path } from '../../../models'
import { createAccommodationGuestsString, to } from '../../../utils'
import {
  BookingSummaryAccommodation,
  LayoutNavigationBack,
  LayoutNavigationBackError500,
  LayoutNavigationBackLoading,
  WarningMissingDataFloating,
} from '../../common'

import { BookingTermsAndConditions } from './common'

const BACK_NAVIGATION_PATH = to(Path.Booking)

export const BookingDetailsFallback: FC = () => {
  const { t } = useTranslation()
  const {
    data: { accommodation },
    ...holiday
  } = useHoliday()

  if (holiday.isError) {
    return <LayoutNavigationBackError500 to={BACK_NAVIGATION_PATH} />
  }

  if (holiday.isSuccess && isNotUndefined(accommodation)) {
    return (
      <LayoutNavigationBack to={BACK_NAVIGATION_PATH} arrowVariant='text'>
        <WarningMissingDataFloating />
        <Box bgcolor={COLORS.white} minHeight='100vh'>
          <Stack paddingTop={unit(24)} paddingX={unit(8)} paddingBottom={unit(4.5)}>
            <Typography variant='titleS' color={COLORS.grey[600]}>
              {t('booking-details.booking-details')}
            </Typography>
            <Typography variant='headlineM' marginTop={unit(1)}>
              {t('booking-details.holiday-club-resorts')}
            </Typography>
          </Stack>
          <Stack gap={unit(3)} padding={unit(3)}>
            <BookingSummaryAccommodation
              accommodationId={accommodation.accommodation_id}
              accommodationStatus={accommodation.status}
              bookingDate={accommodation.booking_date}
              checkInDate={accommodation.start_date}
              checkOutDate={accommodation.end_date}
              paymentStatus={accommodation.payment_status}
              guests={createAccommodationGuestsString(accommodation)}
              price={accommodation.total_amount}
            />
            {/* // TODO 128: Restore price summaries on booking details when a bug in HotelLinx is fixed */}
            {/* //  https://hcrdevelopment.atlassian.net/browse/SII-128 */}
            {/* // <BookingSummaryPrice items={accommodation.prices} total={accommodation.total_amount} /> */}
            <BookingTermsAndConditions />
          </Stack>
        </Box>
      </LayoutNavigationBack>
    )
  }

  return <LayoutNavigationBackLoading to={BACK_NAVIGATION_PATH} />
}
